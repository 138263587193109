export const FingerPrint = ({ className }: { className?: string }) => (
  <svg
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="76" height="76" rx="38" fill="#FFD600" />
    <path
      d="M23 41.5V56.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.4672 21.3167C43.199 19.8121 40.539 19.0066 37.8172 19V19C30.2839 19 24.0172 25.1667 23.1172 33.0833"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.0026 56.5V35.05C52.9971 32.523 52.4277 30.029 51.3359 27.75"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48 44V36.5C48 30.3167 43.5 25.25 38 25.25C32.5 25.25 28 30.25 28 36.5V56.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48 56.5V49"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33 46.5V56.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43 56.5V37.75C43 34.3167 40.75 31.5 38 31.5C35.25 31.5 33 34.3167 33 37.75V41.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38 51.5V56.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38 36.5V46.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
