import { useEffect, useState } from 'react';

import { useTheme } from 'next-themes';

export const Menu = () => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme, resolvedTheme } = useTheme();

  // When mounted on client, now we can show the UI
  useEffect(() => setMounted(true), []);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {mounted && (theme === 'dark' || resolvedTheme === 'dark') ? (
        <>
          <path d="M2 8H22" stroke="white" strokeWidth="2" />
          <path d="M2 16H16" stroke="white" strokeWidth="2" />
        </>
      ) : (
        <>
          <path d="M2 8H22" stroke="#9CA3AF" strokeWidth="2" />
          <path d="M2 16H16" stroke="#9CA3AF" strokeWidth="2" />
        </>
      )}
    </svg>
  );
};
