import { OfficeBuildingIcon, UserCircleIcon } from '@heroicons/react/outline';

import { companyUrl, talentUrl } from 'utils/env';
export const loginOptions = [
  {
    name: 'Company',
    description: 'Login page for a company hiring talents',
    href: `${companyUrl}/invoices`,
    icon: OfficeBuildingIcon,
  },
  {
    name: 'Talent',
    description: 'Login page for a talent working with companies ',
    href: talentUrl,
    icon: UserCircleIcon,
  },
];
