export const Computer = () => (
  <svg
    width="76"
    height="77"
    viewBox="0 0 76 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.874512" width="76" height="76" rx="38" fill="white" />
    <path
      d="M55.328 42.725V23.7879C55.328 23.0152 55.0211 22.2742 54.4747 21.7278C53.9283 21.1815 53.1873 20.8745 52.4146 20.8745H23.2806C22.5079 20.8745 21.7669 21.1815 21.2205 21.7278C20.6741 22.2742 20.3672 23.0152 20.3672 23.7879V42.725H55.328Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.4487 51.7382C59.613 52.0892 59.7051 52.4697 59.7196 52.857C59.734 53.2443 59.6705 53.6306 59.5329 53.9929C59.3952 54.3552 59.1861 54.6862 58.9181 54.9661C58.6501 55.2461 58.3286 55.4694 57.9726 55.6227C57.6013 55.7937 57.1966 55.8799 56.7878 55.8752H18.9136C18.1409 55.8752 17.3999 55.5683 16.8535 55.0219C16.3071 54.4756 16.0002 53.7345 16.0002 52.9618C15.9955 52.5531 16.0817 52.1484 16.2527 51.777L20.3703 42.7261H55.3311L59.4487 51.7382Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.9375 50.0093H40.7643"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
