import { Layout } from 'components/ui/Layout';

export default function Home() {
  return (
    <Layout
      description="Noxx is a payroll and compliance tool that enables companies to hire pseudonymous talent, without compromising legal obligations."
      title="Noxx - Payroll and Compliance tool for pseudonymous talent"
    />
  );
}
