import classNames from 'classnames';

export const Quote = ({ className }: { className?: string }) => (
  <svg
    width="83"
    height="58"
    viewBox="0 0 83 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(className)}
  >
    <g opacity="0.15">
      <path
        d="M60.9654 24.3186C60.9458 23.1452 61.1578 21.9869 61.5892 20.9099C62.0205 19.8328 62.6629 18.8581 63.4793 18.0417C64.2958 17.2252 65.2703 16.583 66.3474 16.1517C67.4244 15.7203 68.5827 15.5083 69.7561 15.5279L76.431 15.9223L76.1834 0.394404L69.5057 0.00269886C63.0221 -0.0935143 56.8471 2.38613 52.3354 6.89778C47.8238 11.4094 45.344 17.5846 45.4402 24.0682L45.8168 57.7221L61.342 57.7221L60.9654 24.3186Z"
        fill="white"
      />
      <path
        d="M58.2264 26.7221H82.2264V57.7221H58.2264V26.7221Z"
        fill="white"
      />
      <path
        d="M15.5279 24.3186C15.5083 23.1452 15.7203 21.9869 16.1517 20.9099C16.583 19.8328 17.2254 18.8581 18.0418 18.0417C18.8583 17.2252 19.8328 16.583 20.9099 16.1517C21.9869 15.7203 23.1452 15.5083 24.3186 15.5279L30.9935 15.9223L30.7459 0.394404L24.0682 0.00269886C17.5846 -0.0935143 11.4096 2.38613 6.89791 6.89778C2.38626 11.4094 -0.0935144 17.5846 0.00269872 24.0682L0.379316 57.7221L15.9045 57.7221L15.5279 24.3186Z"
        fill="white"
      />
      <path
        d="M12.7889 26.7221H36.7889V57.7221H12.7889V26.7221Z"
        fill="white"
      />
    </g>
  </svg>
);
