import { Logo } from 'components/svg/Logo';
import { Twitter } from 'components/svg/Twitter';

export default function Footer() {
  return (
    <>
      <div className="flex flex-col items-center mb-[30px] md:flex-row md:items-center md:justify-between">
        <Logo className="text-black mb-[30px] md:mb-0" />
        <a href="https://twitter.com/no_doxx" target="_blank" rel="noreferrer">
          <Twitter className="hover:opacity-60 text-black" />
        </a>
      </div>
      <div
        id="more-info"
        className="md:flex md:items-center md:justify-between"
      >
        <div className="text-xs text-black flex items-center justify-around">
          <a
            href="https://knotinc.notion.site/Privacy-Policy-d1f467096d0e4145b63ebff6b90312f4"
            target="_blank"
            className="hover:opacity-60"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="https://knotinc.notion.site/Terms-of-Service-a28746d075544c6abde9859125e89172"
            target="_blank"
            className="md:ml-8 hover:opacity-60"
            rel="noreferrer"
          >
            Terms of Service
          </a>
          <a
            href="mailto: hi@noxx.xyz"
            className="md:ml-8 hover:opacity-60"
            rel="noreferrer"
          >
            Contact Us
          </a>
        </div>
        <p className="text-xs text-center  text-black mt-[30px] md:mt-0">
          {`© ${new Date().getFullYear()} Noxx. All right reserved.`}
        </p>
      </div>
    </>
  );
}
