import classNames from 'classnames';

export const Bird = ({ className }: { className?: string }) => (
  <svg
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(className)}
  >
    <rect width="76" height="76" rx="38" fill="#FFD600" />
    <path
      d="M46 36C46 32.6863 48.6863 30 52 30V30C55.3137 30 58 32.6863 58 36V36.25L60 39.5H58V42C58 51.3888 50.3888 59 41 59H37L28.875 66.25L21 59L32.75 55.5L25.6326 52.2534C21.2878 50.2716 18.5 45.9358 18.5 41.1605V41.1605C18.5 40.6273 19.0611 40.2805 19.538 40.519L25.5 43.5L21.6955 39.9672C19.6578 38.0751 18.5 35.42 18.5 32.6393V23L39.9647 43.9877C40.6284 44.6366 41.5197 45 42.448 45H43.5C44.8807 45 46 43.8807 46 42.5V36Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46 40.4663V9L39.184 15.816C37.2177 17.7823 36.159 20.4784 36.2619 23.2572L36.4541 28.4454L34.3256 22.06C34.1664 21.5826 33.5605 21.4395 33.2046 21.7954V21.7954C29.8372 25.1628 28.8915 30.2563 30.8258 34.608L31 35"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="51.5" cy="36.5" r="1.5" fill="black" />
  </svg>
);
