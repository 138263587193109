export const User = () => (
  <svg
    width="76"
    height="77"
    viewBox="0 0 76 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.874512" width="76" height="76" rx="38" fill="white" />
    <path
      d="M53 18.8745H23.6667C22.1939 18.8745 21 20.0684 21 21.5412V56.2078C21 57.6806 22.1939 58.8745 23.6667 58.8745H53C54.4728 58.8745 55.6667 57.6806 55.6667 56.2078V21.5412C55.6667 20.0684 54.4728 18.8745 53 18.8745Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.3379 39.9917C42.3733 39.9917 45.6446 36.7204 45.6446 32.6851C45.6446 28.6497 42.3733 25.3784 38.3379 25.3784C34.3026 25.3784 31.0312 28.6497 31.0312 32.6851C31.0312 36.7204 34.3026 39.9917 38.3379 39.9917Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.3359 52.3189C49.1887 50.1288 47.464 48.2945 45.3488 47.0146C43.2335 45.7347 40.8083 45.0581 38.3359 45.0581C35.8636 45.0581 33.4384 45.7347 31.3231 47.0146C29.2078 48.2945 27.4831 50.1288 26.3359 52.3189"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
