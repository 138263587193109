export const Close = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.92871 19.071L19.0708 4.92891" stroke="white" strokeWidth="2" />
    <path d="M4.92871 4.92896L19.0708 19.0711" stroke="white" strokeWidth="2" />
  </svg>
);
