import classNames from 'classnames';

export const Logo = ({ className }: { className?: string }) => (
  <svg
    width="112"
    height="24"
    viewBox="0 0 112 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(className)}
  >
    <g clipPath="url(#clip0_4232_6362)">
      <path
        d="M60.4077 0L56.3143 3.95181L62.3017 9.71399C62.6113 10.0124 62.8568 10.3668 63.0244 10.7569C63.1919 11.147 63.2781 11.5651 63.2781 11.9874C63.2781 12.4097 63.1919 12.8279 63.0244 13.218C62.8568 13.6081 62.6113 13.9625 62.3017 14.2609L56.2969 20.0496L60.3889 24.0014L66.3937 18.2113C68.1011 16.5594 69.06 14.321 69.06 11.9874C69.06 9.65381 68.1011 7.41554 66.3937 5.76361L60.4077 0Z"
        fill="currentColor"
      />
      <path
        d="M77.0253 9.71399L83.0114 3.95181L78.9194 0L72.9319 5.76361C71.2245 7.41554 70.2656 9.65381 70.2656 11.9874C70.2656 14.321 71.2245 16.5594 72.9319 18.2113L78.9367 24.0014L83.0302 20.0496L77.0253 14.2609C76.7158 13.9625 76.4702 13.6081 76.3027 13.218C76.1351 12.8279 76.0489 12.4097 76.0489 11.9874C76.0489 11.5651 76.1351 11.147 76.3027 10.7569C76.4702 10.3668 76.7158 10.0124 77.0253 9.71399Z"
        fill="currentColor"
      />
      <path
        d="M89.3764 0L85.283 3.95181L91.269 9.71399C91.5786 10.0124 91.8242 10.3668 91.9917 10.7569C92.1592 11.147 92.2454 11.5651 92.2454 11.9874C92.2454 12.4097 92.1592 12.8279 91.9917 13.218C91.8242 13.6081 91.5786 13.9625 91.269 14.2609L85.2656 20.0496L89.3576 24.0014L95.3625 18.2113C97.0698 16.5594 98.0287 14.321 98.0287 11.9874C98.0287 9.65381 97.0698 7.41554 95.3625 5.76361L89.3764 0Z"
        fill="currentColor"
      />
      <path
        d="M105.998 14.2609C105.688 13.9625 105.443 13.6081 105.275 13.218C105.108 12.8279 105.022 12.4097 105.022 11.9874C105.022 11.5651 105.108 11.147 105.275 10.7569C105.443 10.3668 105.688 10.0124 105.998 9.71399L111.984 3.95181L107.891 0L101.905 5.76361C100.197 7.41554 99.2383 9.65381 99.2383 11.9874C99.2383 14.321 100.197 16.5594 101.905 18.2113L107.909 24.0014L112.001 20.0496L105.998 14.2609Z"
        fill="currentColor"
      />
      <path
        d="M45.1621 0.429688L40.8097 0.440805L37.7291 0.429688C35.3124 0.432645 32.9955 1.36077 31.2866 3.01053C29.5778 4.66028 28.6163 6.897 28.6133 9.23011V12.0001V14.7701C28.6163 17.1032 29.5778 19.34 31.2866 20.9897C32.9955 22.6395 35.3124 23.5676 37.7291 23.5706L42.0815 23.5594L45.1621 23.5706C47.5788 23.5676 49.8957 22.6395 51.6046 20.9897C53.3135 19.34 54.2749 17.1032 54.2779 14.7701V12.0001V9.23011C54.2749 6.897 53.3135 4.66028 51.6046 3.01053C49.8957 1.36077 47.5788 0.432645 45.1621 0.429688ZM45.1621 17.9858L42.0815 17.9746L37.7291 17.9858C36.8465 17.9858 36 17.6473 35.3758 17.0448C34.7517 16.4423 34.4011 15.625 34.4011 14.7729V13.4627V12.0001V10.5376V9.23011C34.4011 8.378 34.7517 7.56077 35.3758 6.95825C36 6.35572 36.8465 6.01725 37.7291 6.01725L40.8097 6.02837L45.1621 6.01725C46.0447 6.01725 46.8912 6.35572 47.5154 6.95825C48.1395 7.56077 48.4901 8.378 48.4901 9.23011V10.5376V12.0001V13.4627V14.7701C48.4905 15.1923 48.4047 15.6104 48.2376 16.0005C48.0705 16.3907 47.8254 16.7451 47.5164 17.0438C47.2073 17.3424 46.8403 17.5793 46.4364 17.741C46.0324 17.9026 45.5994 17.9858 45.1621 17.9858Z"
        fill="currentColor"
      />
      <path
        d="M16.5513 0.429688L12.2003 0.440805L9.11971 0.429688C6.70299 0.432645 4.38616 1.36077 2.67727 3.01053C0.968391 4.66028 0.00696927 6.897 0.00390625 9.23011V23.572H5.79172V9.23011C5.79172 8.378 6.14235 7.56077 6.76647 6.95825C7.39059 6.35572 8.23707 6.01725 9.11971 6.01725L12.2003 6.02837L16.5513 6.01725C17.4339 6.01725 18.2804 6.35572 18.9045 6.95825C19.5286 7.56077 19.8793 8.378 19.8793 9.23011V23.572H25.6671V9.23011C25.664 6.897 24.7026 4.66028 22.9938 3.01053C21.2849 1.36077 18.968 0.432645 16.5513 0.429688Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4232_6362">
        <rect width="112" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
