import { strategy } from 'utils/misc';

export type Variant = 'primary' | 'secondary' | 'outline';
export type Theme = 'dark' | 'white' | 'yellow';
export type Size = 'sm' | 'xl';

export const buttonSizeRecord: Record<Size, string> = {
  sm: 'py-3 px-6 text-base font-medium rounded-[40px]',
  xl: 'py-[22px] px-10 text-base rounded-[40px]',
};

export const getButtonTheme = (
  theme: Theme,
  variant: Variant,
  disabled: boolean | undefined,
) => {
  switch (variant) {
    case 'primary':
      return strategy(
        {
          dark: `border-transparent enabled:hover:bg-white focus:outline-none focus:ring-2 focus:ring-gray-100  focus:ring-offset-2 focus:ring-offset-gray-100 ${
            disabled ? 'text-white bg-[#262626]' : ' text-black bg-[#FFD600]'
          }`,
          white: `border-transparent enabled:hover:bg-black hover:text-white ${
            disabled ? 'text-white bg-[#262626]' : ' text-black bg-[#FFD600]'
          }`,
          yellow: `border-transparent enabled:hover:bg-black hover:text-white ${
            disabled ? 'text-white bg-[#FFD600]' : ' text-black bg-white'
          }`,
        },
        theme,
      );
    case 'secondary':
      return strategy(
        {
          dark: `border-transparent enabled:hover:bg-[#FFD600] focus:outline-none focus:ring-2 focus:ring-gray-200  focus:ring-offset-2 focus:ring-offset-gray-200 ${
            disabled ? 'text-white bg-[#262626]' : ' text-black bg-white'
          }`,
          white: `border-transparent enabled:hover:bg-[#FFD600] focus:outline-none focus:ring-2 focus:ring-gray-200  focus:ring-offset-2 focus:ring-offset-gray-200 ${
            disabled ? 'text-white bg-[#262626]' : ' text-black bg-white'
          }`,
          yellow: `border-transparent enabled:hover:bg-[#FFD600] focus:outline-none focus:ring-2 focus:ring-gray-200  focus:ring-offset-2 focus:ring-offset-gray-200 ${
            disabled ? 'text-white bg-[#262626]' : ' text-black bg-white'
          }`,
        },
        theme,
      );
    case 'outline':
      return strategy(
        {
          dark: 'border-white border text-white hover:bg-white hover:text-black',
          white:
            'border-transparent outline text-black outline-offset-2 outline-1 hover:outline-2',
          yellow:
            'border-transparent rounded-[40px] border-solid border-1 border-[#000000] text-black hover:bg-black hover:text-white',
        },
        theme,
      );
    default:
      break;
  }
};
