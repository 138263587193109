import { ReactNode, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import 'swiper/css/swiper.css';

import { Badge } from 'components/svg/Badge';
import { Quote } from 'components/svg/Quote';
import { TalentProfile } from 'components/svg/TalentProfile';
import { Button } from 'components/ui/Button';
import { MenuOverlay } from 'components/ui/MenuOverlay';
import { NavBar } from 'components/ui/NavBar';
import { talentUrl } from 'utils/env';
import { loginOptions } from 'utils/loginOptions';

import Footer from '../Footer';
import { AVATAR_LIST, FEATURES, OUR_BELIEFS } from './index.constants';

interface LayoutProps {
  description: string;
  title: string;
  children?: ReactNode;
  footer?: ReactNode;
  isLoading?: boolean;
}

const params = {
  preloadImages: false,
  autoplay: {
    delay: 2000,
  },
  loop: true,
  watchSlidesProgress: true,
  speed: 1000,
  parallax: true,
};

export const Layout = ({ title, description }: LayoutProps) => {
  const router = useRouter();
  const url = router.asPath;
  const [navbarOpen, setNavbarOpen] = useState(false);
  const itemsRef = useRef([] as any);
  const [email, setEmail] = useState('');

  const handleCompanySignup = () => {
    window.location.href = 'https://ukokm0ym8fl.typeform.com/to/v6gFKCCp';
  };
  const handleTalentLogin = () => {
    window.location.href = talentUrl as string;
  };

  useEffect(() => {
    // Callback for IntersectionObserver
    const callback = function (entries) {
      entries.forEach((entry) => {
        // Is the element in the viewport?
        if (entry.boundingClientRect.top > 0) {
          if (entry.isIntersecting) {
            // Add the margin class:
            if (entry.target.id === 'normal-row') {
              entry.target.classList.add('-ml-[80%]');
            }

            if (entry.target.id === 'row-reverse') {
              entry.target.classList.add('lg:ml-[80%]', '-ml-[80%]');
            }
          } else {
            // Otherwise remove the fadein class
            if (entry.target.id === 'normal-row') {
              entry.target.classList.remove('-ml-[80%]');
            }

            if (entry.target.id === 'row-reverse') {
              entry.target.classList.remove('lg:ml-[80%]', '-ml-[80%]');
            }
          }
        }
      });
    };

    const mediaQuery = window.matchMedia('(min-width: 768px)');

    const observer = new IntersectionObserver(
      callback,
      mediaQuery.matches
        ? {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
          }
        : {},
    );

    // Loop through each of the target
    if (!itemsRef.current && itemsRef.current.length === 0) return;
    itemsRef.current.forEach(function (target) {
      // Add the element to the watcher
      observer.observe(target);
    });
  }, []);

  return (
    <div className="flex flex-col justify-between items-center">
      <NextSeo
        title={title}
        description={description}
        canonical="https://www.noxx.xyz/"
        openGraph={{
          url,
          title,
        }}
      />

      <MenuOverlay navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
      <section className="w-full flex flex-col items-center">
        <div className="max-w-[1440px] w-full px-4 pt-4 md:px-[130px] md:pt-8 md:pb-[220px] pb-20">
          <NavBar options={loginOptions} setNavbarOpen={setNavbarOpen} />

          <div className="w-full flex flex-col items-center md:mt-[84px] mt-[60px]">
            <div className="flex flex-col items-center md:max-w-[900px] md:mb-[120px] mb-[60px]">
              <p className="text-sm font-semibold text-white mb-5 leading-6 tracking-[1px] uppercase">
                Don't Doxx Yourself For Work
              </p>
              <h1 className="text-[34px] text-white leading-[100%] md:text-[70px] text-center">
                Work With Freedom
              </h1>
              <div className="mt-6 flex flex-col items-center">
                <p className="text-xl text-white text-center max-w-[375px] md:max-w-[500px] lg:max-w-[720px] mb-[60px]">
                  Noxx is a simple and secure way to create a pseudonymous
                  resume and receive payments without exposing your original
                  identity.
                </p>
                <div className="flex flex-col md:flex-row space-y-4 md:h-12 md:space-x-6 md:items-baseline">
                  <Button
                    className="justify-center md:w-[260px]"
                    variant="secondary"
                    theme="dark"
                    size="xl"
                    onClick={handleTalentLogin}
                  >
                    Create a Profile
                  </Button>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col items-center">
              <div className="flex flex-col items-center md:max-w-[776px] md:mb-[120px] mb-[60px]">
                <p className="text-lg text-white mb-5 leading-6 max-w-[315px] text-center">
                  Stay ahead of pseudonymous job and talent trends with our
                  newsletter
                </p>

                <form
                  className="flex"
                  action="https://xyz.us21.list-manage.com/subscribe/post?u=6eabd7cdc7bbe487d6a4fb7b2&amp;id=62c5c4e1ed&amp;f_id=00845ae1f0"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  target="_self"
                >
                  <div className="flex items-center justify-between p-2 rounded-full bg-[#2D2D2D]">
                    <input
                      className="bg-transparent flex-grow border-none focus:border-transparent focus:ring-0 outline-none min-w-[275px]"
                      placeholder="Your Email Address"
                      type="email"
                      value={email}
                      name="EMAIL"
                      id="mce-EMAIL"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <div className="hidden" aria-hidden="true">
                      <input
                        type="text"
                        name="b_6eabd7cdc7bbe487d6a4fb7b2_62c5c4e1ed"
                        tabIndex={-1}
                        value=""
                      />
                    </div>

                    <button className="bg-[#FFD600] hover:bg-white text-black py-4 px-6 rounded-full">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="w-full flex flex-col items-center">
              <div className="flex flex-col items-center md:w-full">
                <TalentProfile className="w-full max-w-full" />
              </div>
            </div>
          </div>

          {/* <div className="w-full flex flex-col items-center mb-28">
            <div className="flex flex-col items-center md:w-full">
              <div className="flex flex-col justify-center items-center text-center">
                <p className="font-bold sm:text-[32px] text-[24px] sm:mb-[50px] mb-[60px]">
                  Companies seeking talent via Noxx
                </p>
                <div className="grid gap-6">
                  <div className="grid sm:grid-cols-4 gap-4 grid-cols-2 justify-items-center align-items-center md:space-x-10">
                    <SmolQuants />

                    <Gcr />

                    <MirrorWorld />

                    <Favo />
                  </div>
                  <div className="grid sm:grid-cols-3 gap-4 grid-cols-2 justify-items-center align-items-center">
                    <div>
                      <Premia />
                    </div>
                    <div>
                      <Image
                        width={180}
                        height={150}
                        alt="Immuna company logo"
                        src="/immuna.png"
                      />
                    </div>
                    <div>
                      <Destore />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="w-full flex items-center flex-col lg:flex-row lg:justify-between lg:mt-[60px] mt-[30px]">
            <div className="md:max-w-[473px]">
              <h1 className="text-[28px] leading-[100%] sm:text-[60px] md:text-[60px] text-white font-medium">
                Work without friction
              </h1>
              <p className="text-xl font-normal leading-[150%] text-white mt-10 md:mt-[50px]">
                Multilayered digital identities are already an important part of
                everyday life for millions of users, empowering them to escape
                biases and be judged on their merits.
              </p>
              <p className="text-xl font-normal leading-[150%] text-white mt-6">
                Now Noxx is bringing that same freedom to the world of work.
              </p>
              <p className="text-xl font-bold leading-[150%] text-white mt-6 text-left w-full">
                Our tools enable top talent to create a pseudonymous identity in
                a matter of moments, making it simple for them to be hired
                without revealing their sensitive information.
              </p>
            </div>
            <img
              className="mt-[60px] max-w[288px] w-full xl:mt-0 md:max-w-[574px] md:w-[50%] xl:ml-[133px] lg:ml-[60px]"
              src="/images/users/user.png"
              alt="user"
            />
          </div>
        </div>
      </section>

      <section
        id="how-works"
        className="bg-[#FFD600] w-full flex flex-col items-center overflow-x-hidden text-black"
      >
        <div className="max-w-[1440px] w-full px-4 py-20 md:py-[150px] md:px-[130px]">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="md:w-full">
              <h1 className="text-[28px] leading-[100%] sm:text-[60px] md:text-[60px] text-dark font-medium md:mb-[70px] mb-10">
                Get started in minutes
              </h1>
              <ul className="flex flex-col">
                {FEATURES.map((feature, key) => (
                  <li
                    key={key}
                    className="flex flex-col md:flex-row md:items-center md:mb-[38px] mb-[30px] max-w-[574px]"
                  >
                    <div className="mb-[20px] md:mb-0">{feature.icon}</div>
                    <p className="text-[20px] leading-[150%] font-normal md:ml-8">
                      {feature.description}
                    </p>
                  </li>
                ))}
              </ul>

              <Button
                className="justify-center"
                theme="yellow"
                onClick={handleTalentLogin}
              >
                Sign up now
              </Button>
            </div>

            <img
              className="max-w-[620px] md:max-w-3xl 2xl:relative 2xl:right-0  xl:absolute xl:right-[330px] -mr-[330px] xl:max-w-[580px] xl:min-h-[730px] xl:object-cover xl:object-left  min-h-[365px] 2xl:max-w-full 2xl:object-fill 2xl:-mr-0 2xl:ml-10"
              src="/images/others/window.png"
              alt="window"
            />
          </div>
        </div>
      </section>

      <section id="why-noxx" className="w-full flex flex-col items-center">
        <div className="max-w-[1440px] w-full px-4 py-20 md:py-[150px] xl:px-[332px] lg:px-[226px]">
          <h1 className="text-[28px] md:text-[60px] leading-[100%] text-white font-medium text-center">
            Noxx sets talent free
          </h1>
          <ul className="mt-10 md:mt-[120px]">
            {AVATAR_LIST.map((avatar, key) => (
              <li
                className={classNames(
                  'flex items-center flex-col lg:flex-row lg:max-w-[776px] lg:max-h-[280px] md:mb-[120px]',
                  avatar.className,
                )}
                key={key}
              >
                <div className="relative mb-[30px] lg:mb-0 max-w-[180px] w-[63%] lg:max-w-[271px] lg:w-[35%] rounded-[15px]">
                  <img
                    ref={(el) => (itemsRef.current[key] = el)}
                    className="absolute w-screen rounded-[15px] transform duration-500 delay-200 h-full"
                    style={{
                      boxShadow: '40px 40px 40px rgba(0, 0, 0, 0.5)',
                    }}
                    src={avatar.avatar.src}
                    alt="profile-avatar"
                    id={avatar.id}
                  />
                  <img
                    className="rounded-[15px] mt-[1px]"
                    src={avatar.avatar.backImg}
                    alt="profile"
                  />
                </div>

                <div className="lg:mb-0 mb-[30px]">
                  <p className="text-2xl font-bold text-white mb-[30px]">
                    {avatar.title}
                  </p>
                  <p className="relative max-w-[288px] text-xl font-normal text-white lg:max-w-[372px]">
                    <Quote className="absolute w-[41px] h-[28px] -left-4 -top-1 lg:-left-9 lg:-top-6 lg:w-[82.23px] lg:h-[57.72px]" />
                    {avatar.description}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="bg-black w-full flex flex-col items-center">
        <div className="max-w-[1440px] w-full px-4 py-20 flex items-center md:flex-row flex-col md:justify-between xl:pt-[228px]  xl:px-[44px] 2xl:px-[130px] md:pt-[228px] md:px-0 md:pb-0">
          <div className="relative md:-mt-[228px] md:-ml-[130px] -mt-[80px] -mx-[16px]">
            <img
              className="max-w-[443px] w-full md:max-w-[603px] md:rounded-br-[300px] rounded-br-[159px]"
              src="/images/banners/desktop7.png"
              alt=""
            />
            <Badge className="max-w-[146px] top-[59%] left-[38%] absolute md:left-[48%] md:top-[62%] md:max-w-[221px] lg:max-w-[281px] lg:top-[62%] lg:left-[78%]" />
            <img
              className="max-w-[136px] 372px top-[22%] right-0 absolute  md:max-w-[171px]  lg:max-w-[271px] lg:w-[45%] lg:absolute lg:top-[26%] lg:left-[78%]"
              src="/images/avatars/avatar5.png"
              alt=""
            />
          </div>
          <div className="max-w-[288px] w-full my-10 lg:my-0 xl:max-w-[473px] lg:max-w-[373px] self-start">
            <h1 className="text-[28px] xl:text-[60px] md:text-[40px] lg:text-[40px] text-white font-medium leading-[100%]">
              Work and Hire Without Friction
            </h1>
            <p className="text-[20px] mt-10 text-white">
              Noxx also incorporates a payroll and compliance tool that makes it
              easy for pseudonymous talent to get hired and paid without
              compromising legal obligations.
            </p>
          </div>
        </div>
      </section>

      <section id="beliefs" className="w-full flex flex-col items-center">
        <div className="max-w-[1440px] w-full px-4 py-20 lg:py-[150px] lg:px-[130px]">
          <h1 className="text-[28px] md:text-[60px] text-white font-medium text-center">
            Our beliefs
          </h1>

          <div className="lg:mt-[120px] flex items-center justify-center lg:max-w-[1,180px] w-full">
            <ul className="lg:flex xl:items-center lg:justify-start">
              {OUR_BELIEFS.map((belief, key) => (
                <li
                  key={key}
                  className="max-w-[372px] min-h-[354px] w-full lg:mr-8 my-10 lg:my-0"
                >
                  <div className="mb-6">{belief.icon}</div>
                  <p className="text-[32px] mb-6 text-white font-bold leading-[100%] lg:leading-10">
                    {belief.title}
                  </p>
                  <p className="text-[20px] text-white font-normal leading-[150%]">
                    {belief.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <footer className="w-full flex flex-col items-center bg-[#FFD600] text-black pt-6">
        <div className="max-w-[1440px] w-full px-4 py-10 md:pt-[75px] md:pb-[100px] md:px-[130px]">
          <div className="max-w-[1180px]">
            <div className="md:w-[500px] m-auto">
              <h1 className="text-[28px] text-center sm:text-[60px] md:text-[60px] text-dark font-medium mb-12 leading-[100%]">
                Work with true pseudonymity
              </h1>
            </div>
            <div className="flex flex-col items-center md:flex-row md:justify-center">
              <Button
                className="justify-center w-full mb-4 md:mb-0 md:mr-4 md:py-[22px] md:px-10 md:text-base md:rounded-[40px] md:w-[260px]"
                theme="yellow"
                onClick={handleTalentLogin}
              >
                Get your avatar
              </Button>
              <Button
                theme="yellow"
                variant="outline"
                className="justify-center w-full md:py-[22px] md:px-10 md:text-base md:rounded-[40px] md:w-[260px]"
                onClick={handleCompanySignup}
              >
                Start hiring
              </Button>
            </div>
            <div className="mt-[120px]">
              <Footer />
            </div>
          </div>
        </div>
      </footer>

      <style global jsx>
        {`
          html {
            scroll-behavior: smooth;
          }
        `}
      </style>
    </div>
  );
};
