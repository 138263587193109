import { Belief } from 'components/svg/Belief';
import { Bird } from 'components/svg/Bird';
import { Computer } from 'components/svg/Computer';
import { FingerPrint } from 'components/svg/FingerPrint';
import { User } from 'components/svg/User';
import { Wallet } from 'components/svg/Wallet';

export const FEATURES = [
  {
    icon: <Computer />,
    description:
      'Create your pseudonymous resume and profile with our AI-powered tools',
  },
  {
    icon: <User />,
    description:
      'Verify your work eligibility with our pseudonymous verification system',
  },
  {
    icon: <Wallet />,
    description: 'Get hired and get paid securely with crypto payments!',
  },
];

export const AVATAR_LIST = [
  {
    id: 'normal-row',
    avatar: {
      username: 'Goodle',
      role: 'Developer',
      src: '/images/avatars/avatar2.png',
      backImg: '/images/users/user2.png',
      variant: 'white',
    },
    className: 'lg:flex-row lg:space-x-[133px]',
    title: 'Free from corporate surveillance',
    description:
      'I’m an experienced dev and everyone knows this is a competitive industry where people change jobs a lot. I want to be able to dip my toes in the market and see what else is out there without my boss immediately knowing.',
  },
  {
    id: 'row-reverse',
    avatar: {
      username: 'Boredbirds',
      role: 'Growth',
      backImg: '/images/users/user3.png',
      src: '/images/avatars/avatar3.png',
      variant: 'yellow',
    },
    className: 'lg:flex-row-reverse lg:space-x-[133px] lg:space-x-reverse',
    title: 'Free from doxxing',
    description: `I hate the idea that there is just no separation between my work and personal life anymore. 

      I don’t want my boss to know if I let loose on the weekend. I don’t want to be pulled up in front of HR because someone doesn’t like my tweets or decides to harass me.`,
  },
  {
    id: 'normal-row',
    avatar: {
      username: 'Boredbirds',
      role: 'Graphic Designer',
      backImg: '/images/users/user4.png',
      src: '/images/avatars/avatar4.png',
      variant: 'dark',
    },
    className: 'lg:flex-row lg:space-x-[133px]',
    title: 'Free from profiling',
    description:
      'Having faced discrimination at work before, it was draining to worry that it might happen again. I just want to be judged on my abilities, not the color of my skin',
  },
];

export const OUR_BELIEFS = [
  {
    icon: <Bird />,
    title: 'Pseudonymity is freedom',
    description:
      'Noxx empowers a world where workers can be free from discrimination, knowing that they are being judged based on their merits, not an employer’s biases',
  },
  {
    icon: <FingerPrint />,
    title: 'Pseudonymity is uncompromised ',
    description:
      'Noxx is a custom built solution that’s specifically designed to ensure that talents’ privacy is always protected',
  },
  {
    icon: <Belief />,
    title: 'Pseudonymity is the future of work',
    description:
      'Noxx uniquely enables legal entities to hire the very best pseudonymous talent, while still maintaining the highest standards of compliance and accountability',
  },
];

export const BANNERS = [
  {
    background: '/images/banners/desktop1.png',
    avatar: '/images/avatars/avatar1.png',
  },
  {
    background: '/images/banners/desktop2.png',
    avatar: '/images/avatars/avatar6.png',
  },
  {
    background: '/images/banners/desktop3.png',
    avatar: '/images/avatars/avatar7.png',
  },
];
