import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';

import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Logo } from 'components/svg/Logo';
import { Menu } from 'components/svg/Menu';
import { Twitter } from 'components/svg/Twitter';

import { Button } from '../Button';

interface Options {
  description: string;
  icon: any;
  name: string;
  href?: string;
}

interface NavBarProps {
  options: Options[];
  setNavbarOpen: Dispatch<SetStateAction<boolean>>;
  className?: string;
}

export const NavBar = ({ options, className, setNavbarOpen }: NavBarProps) => {
  const [urlHash, setUrlHash] = useState('');
  const { asPath } = useRouter();

  const handleCompanySignup = () => {
    window.location.href = 'https://ukokm0ym8fl.typeform.com/to/v6gFKCCp';
  };

  useEffect(() => {
    setUrlHash(asPath.split('#')[1]);
  }, [asPath]);

  const classLink = (link: string) =>
    classNames(
      'group text-base font-medium transition duration-200 hover:text-[#FFD600]',
      urlHash === link ? 'text-[#FFD600]' : 'text-white',
    );

  const classUnderline = (link: string) =>
    classNames(
      'block group-hover:max-w-full transition-all duration-300 h-0.5 bg-[#ffd600]',
      urlHash === link ? 'max-w-full' : 'max-w-0',
    );
  return (
    <header className={classNames(className)}>
      <Popover className="relative">
        <div className="flex items-center justify-between h-12">
          <Logo className="text-white" />

          <Popover.Group as="nav" className="hidden lg:flex space-x-8">
            <Link href="#why-noxx" className={classLink('why-noxx')}>
              Why Noxx?
              <span className={classUnderline('why-noxx')} />
            </Link>
            <Link href="#how-works" className={classLink('how-works')}>
              How it works
              <span className={classUnderline('how-works')} />
            </Link>
            <Link href="/blog" className={classLink('blog')}>
              Blog
              <span className={classUnderline('blog')} />
            </Link>
          </Popover.Group>

          <Popover>
            {() => (
              <>
                <div className="flex items-center">
                  <a
                    href="https://twitter.com/no_doxx"
                    target="_blank"
                    rel="noreferrer"
                    className="hidden lg:block"
                  >
                    <Twitter className="hover:opacity-60 text-white w-6 h-6" />
                  </a>

                  <Button
                    className="hidden lg:block ml-3"
                    variant="outline"
                    theme="dark"
                    size="sm"
                    onClick={handleCompanySignup}
                  >
                    Hiring? talk to us
                  </Button>

                  <Popover.Button className="transition-all duration-140 hidden lg:flex text-base text-black font-medium py-3 px-6 bg-[#FFD600] border-transparent enabled:hover:bg-white focus:outline-none focus:ring-2 focus:ring-gray-100  focus:ring-offset-2 focus:ring-offset-gray-100  rounded-[40px] ml-3">
                    Login
                  </Popover.Button>
                  <div
                    className="lg:hidden ml-4"
                    onClick={() => setNavbarOpen(true)}
                  >
                    <span className="sr-only">Open menu</span>
                    <Menu />
                  </div>
                </div>

                <Transition
                  as="div"
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                        {options &&
                          options.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              target="_blank"
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                              rel="noreferrer"
                            >
                              <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-[#FCCA46] text-[#1C1C1C] sm:h-12 sm:w-12">
                                <item.icon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </a>
                          ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      </Popover>
    </header>
  );
};
