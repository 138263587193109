/* eslint-disable @next/next/no-html-link-for-pages */
import { Dispatch, SetStateAction } from 'react';

import Link from 'next/link';

import { Close } from 'components/svg/Close';
import { Logo } from 'components/svg/Logo';
import { Twitter } from 'components/svg/Twitter';
import { Button } from 'components/ui/Button';

export const MenuOverlay = ({
  navbarOpen,
  setNavbarOpen,
}: {
  navbarOpen: boolean;
  setNavbarOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const handleRedirectTypeForm = () => {
    window.location.href = 'https://ukokm0ym8fl.typeform.com/to/v6gFKCCp';
  };
  return (
    <>
      <nav
        className={`flex lg:hidden fixed top-0 left-0 w-full p-4 z-10 h-screen bg-black text-white bg-opacity-100 transform delay-100 transition-all duration-300 ${
          navbarOpen
            ? 'opacity-100 translate-x-0'
            : 'opacity-0 -translate-x-full'
        }`}
      >
        <div className="flex flex-col items-center w-full">
          <div className="flex items-center justify-between w-full mb-[73px] p-4">
            <Logo className="text-white" />
            <div
              className="lg:hidden ml-4"
              onClick={() => setNavbarOpen(false)}
            >
              <span className="sr-only">Open menu</span>
              <Close />
            </div>
          </div>

          <ul className="w-full flex flex-col items-center">
            <li className="nav-li text-2xl font-bold mb-10">
              <span
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  setNavbarOpen(false);
                  window.location.hash = '#why-noxx';
                }}
              >
                Why Noxx?
              </span>
            </li>
            <li className="nav-li text-2xl font-bold mb-10">
              <span
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  setNavbarOpen(false);
                  window.location.hash = '#how-works';
                }}
              >
                How it works
              </span>
            </li>
            <li className="nav-li text-2xl font-bold mb-[73px]">
              <Link href="/blog" className="text-white">
                Blog
              </Link>
            </li>

            <Button
              className="mt-[30px] lg:mt-0 mb-6"
              variant="secondary"
              theme="dark"
              size="xl"
              onClick={handleRedirectTypeForm}
            >
              Start hiring talent
            </Button>
            <a href="https://twitter.com/no_doxx">
              <Twitter />
            </a>
          </ul>
        </div>
      </nav>
    </>
  );
};
