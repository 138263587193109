export const Belief = ({ className }: { className?: string }) => (
  <svg
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="76" height="76" rx="38" fill="#FFD600" />
    <path
      d="M22 32.5C22 22.835 29.9519 15 39.6169 15V15C49.1536 15 57 22.7311 57 32.2678V32.2678C57 36.0242 56.0197 39.7155 54.1561 42.9769L53.25 44.5625C51.7756 47.1428 51 50.0632 51 53.035V61H32V54.75H24V45.5H19L22 35V32.5Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="39"
      cy="24"
      r="3"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="39"
      cy="41"
      r="3"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42 41H46.7574C47.553 41 48.3161 41.3161 48.8787 41.8787L52 45"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="47"
      cy="32"
      r="3"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="31"
      cy="32"
      r="3"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50 32H56"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42 24H45.7574C46.553 24 47.3161 23.6839 47.8787 23.1213L50.5 20.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 29L24 25"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
