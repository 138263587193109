export const Wallet = () => (
  <svg
    width="76"
    height="77"
    viewBox="0 0 76 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.874512" width="76" height="76" rx="38" fill="white" />
    <path
      d="M56.209 50.6872V53.4164C56.209 54.8641 55.6339 56.2525 54.6102 57.2761C53.5866 58.2998 52.1982 58.8748 50.7505 58.8748H23.4584C22.0108 58.8748 20.6224 58.2998 19.5987 57.2761C18.5751 56.2525 18 54.8641 18 53.4164V34.3119C18 32.8643 18.5751 31.4759 19.5987 30.4523C20.6224 29.4286 22.0108 28.8535 23.4584 28.8535H50.7505C52.1982 28.8535 53.5866 29.4286 54.6102 30.4523C55.6339 31.4759 56.209 32.8643 56.209 34.3119V37.0411"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.753 28.8517L46.5137 20.373C46.1897 19.7317 45.6259 19.2441 44.9446 19.0159C44.2634 18.7876 43.5196 18.8373 42.8747 19.1539V19.1539L23.4609 28.8517"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.2086 50.6866C56.9324 50.6866 57.6266 50.399 58.1384 49.8872C58.6502 49.3754 58.9378 48.6812 58.9378 47.9574V39.7697C58.9378 39.0459 58.6502 38.3517 58.1384 37.8399C57.6266 37.3281 56.9324 37.0405 56.2086 37.0405H49.3855C47.5759 37.0405 45.8405 37.7594 44.5609 39.0389C43.2814 40.3185 42.5625 42.054 42.5625 43.8636C42.5625 45.6731 43.2814 47.4086 44.5609 48.6882C45.8405 49.9677 47.5759 50.6866 49.3855 50.6866H56.2086Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.3789 43.1892C49.5112 43.1928 49.6395 43.2353 49.7478 43.3114C49.8561 43.3875 49.9396 43.4939 49.9877 43.6171C50.0359 43.7404 50.0467 43.8752 50.0187 44.0046C49.9907 44.1339 49.9252 44.2522 49.8303 44.3445C49.7355 44.4368 49.6156 44.4992 49.4855 44.5237C49.3554 44.5482 49.221 44.5339 49.0991 44.4824C48.9771 44.431 48.873 44.3447 48.7998 44.2345C48.7266 44.1242 48.6875 43.9948 48.6875 43.8624C48.6875 43.7725 48.7055 43.6834 48.7404 43.6005C48.7754 43.5176 48.8266 43.4426 48.8911 43.3798C48.9555 43.3171 49.0319 43.2679 49.1157 43.2351C49.1995 43.2024 49.289 43.1868 49.3789 43.1892"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
