import { ButtonHTMLAttributes } from 'react';

import classNames from 'classnames';

import {
  Variant,
  Size,
  Theme,
  buttonSizeRecord,
  getButtonTheme,
} from './index.constants';

interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'size'> {
  size?: Size;
  theme?: Theme;
  variant?: Variant;
}

export const Button = ({
  children,
  theme = 'dark',
  size = 'sm',
  variant = 'primary',
  type = 'button',
  className,
  ...props
}: ButtonProps) => (
  <button
    type={type}
    className={classNames(
      'inline-flex items-center border shadow-sm font-medium disabled:opacity-25 transition-all duration-140',
      buttonSizeRecord[size],
      getButtonTheme(theme, variant, props.disabled),
      className,
    )}
    {...props}
  >
    {children}
  </button>
);
